/* tslint:disable */
/* eslint-disable */
export enum RenderStyle {
    Photorealistic = 'Photorealistic',
    Render = 'Render',
    Watercolor = 'Watercolor',
    WatercolorSketch = 'Watercolor sketch',
    PencilSketch = 'Pencil sketch',
    InkSketch = 'Ink sketch',
    VectorGraphics = 'Vector graphics'
}
