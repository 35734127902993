/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseApiService } from '../base-api-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { VisoidCallbackDto } from '../models/visoid-callback-dto';
import { VisoidImagesDto } from '../models/visoid-images-dto';
import { VisoidRenderResponseDto } from '../models/visoid-render-response-dto';
import { VisoidRequestDto } from '../models/visoid-request-dto';
import { VisoidUploadDto } from '../models/visoid-upload-dto';
import { VisoidUploadResponseDto } from '../models/visoid-upload-response-dto';

@Injectable({
    providedIn: 'root'
})
export class VisoidApiService extends BaseApiService {
    constructor(config: ApiConfiguration, http: HttpClient) {
        super(config, http);
    }

    /**
     * Path part for operation visoidControllerGetImages
     */
    static readonly VisoidControllerGetImagesPath = '/visoid/{draftPhaseId}/images';

    /**
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `visoidControllerGetImages()` instead.
     *
     * This method doesn't expect any request body.
     */
    visoidControllerGetImages$Response(params: {
        draftPhaseId: string;
        context?: HttpContext;
    }): Observable<StrictHttpResponse<Array<VisoidImagesDto>>> {
        const rb = new RequestBuilder(
            this.rootUrl,
            VisoidApiService.VisoidControllerGetImagesPath,
            'get'
        );
        if (params) {
            rb.path('draftPhaseId', params.draftPhaseId, {});
        }

        return this.http
            .request(
                rb.build({
                    responseType: 'json',
                    accept: 'application/json',
                    context: params?.context
                })
            )
            .pipe(
                filter((r: any) => r instanceof HttpResponse),
                map((r: HttpResponse<any>) => {
                    return r as StrictHttpResponse<Array<VisoidImagesDto>>;
                })
            );
    }

    /**
     * This method provides access to only to the response body.
     * To access the full response (for headers, for example), `visoidControllerGetImages$Response()` instead.
     *
     * This method doesn't expect any request body.
     */
    visoidControllerGetImages(params: {
        draftPhaseId: string;
        context?: HttpContext;
    }): Observable<Array<VisoidImagesDto>> {
        return this.visoidControllerGetImages$Response(params).pipe(
            map((r: StrictHttpResponse<Array<VisoidImagesDto>>) => r.body as Array<VisoidImagesDto>)
        );
    }

    /**
     * Path part for operation visoidControllerUpload
     */
    static readonly VisoidControllerUploadPath = '/visoid/{draftPhaseId}/upload';

    /**
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `visoidControllerUpload()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    visoidControllerUpload$Response(params: {
        draftPhaseId: string;
        context?: HttpContext;
        body: VisoidUploadDto;
    }): Observable<StrictHttpResponse<VisoidUploadResponseDto>> {
        const rb = new RequestBuilder(
            this.rootUrl,
            VisoidApiService.VisoidControllerUploadPath,
            'post'
        );
        if (params) {
            rb.path('draftPhaseId', params.draftPhaseId, {});
            rb.body(params.body, 'application/json');
        }

        return this.http
            .request(
                rb.build({
                    responseType: 'json',
                    accept: 'application/json',
                    context: params?.context
                })
            )
            .pipe(
                filter((r: any) => r instanceof HttpResponse),
                map((r: HttpResponse<any>) => {
                    return r as StrictHttpResponse<VisoidUploadResponseDto>;
                })
            );
    }

    /**
     * This method provides access to only to the response body.
     * To access the full response (for headers, for example), `visoidControllerUpload$Response()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    visoidControllerUpload(params: {
        draftPhaseId: string;
        context?: HttpContext;
        body: VisoidUploadDto;
    }): Observable<VisoidUploadResponseDto> {
        return this.visoidControllerUpload$Response(params).pipe(
            map(
                (r: StrictHttpResponse<VisoidUploadResponseDto>) =>
                    r.body as VisoidUploadResponseDto
            )
        );
    }

    /**
     * Path part for operation visoidControllerRender
     */
    static readonly VisoidControllerRenderPath = '/visoid/{draftPhaseId}/render/{sourceImageId}';

    /**
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `visoidControllerRender()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    visoidControllerRender$Response(params: {
        draftPhaseId: string;
        sourceImageId: string;
        context?: HttpContext;
        body: VisoidRequestDto;
    }): Observable<StrictHttpResponse<VisoidRenderResponseDto>> {
        const rb = new RequestBuilder(
            this.rootUrl,
            VisoidApiService.VisoidControllerRenderPath,
            'post'
        );
        if (params) {
            rb.path('draftPhaseId', params.draftPhaseId, {});
            rb.path('sourceImageId', params.sourceImageId, {});
            rb.body(params.body, 'application/json');
        }

        return this.http
            .request(
                rb.build({
                    responseType: 'json',
                    accept: 'application/json',
                    context: params?.context
                })
            )
            .pipe(
                filter((r: any) => r instanceof HttpResponse),
                map((r: HttpResponse<any>) => {
                    return r as StrictHttpResponse<VisoidRenderResponseDto>;
                })
            );
    }

    /**
     * This method provides access to only to the response body.
     * To access the full response (for headers, for example), `visoidControllerRender$Response()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    visoidControllerRender(params: {
        draftPhaseId: string;
        sourceImageId: string;
        context?: HttpContext;
        body: VisoidRequestDto;
    }): Observable<VisoidRenderResponseDto> {
        return this.visoidControllerRender$Response(params).pipe(
            map(
                (r: StrictHttpResponse<VisoidRenderResponseDto>) =>
                    r.body as VisoidRenderResponseDto
            )
        );
    }

    /**
     * Path part for operation visoidControllerCallback
     */
    static readonly VisoidControllerCallbackPath = '/visoid/callback';

    /**
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `visoidControllerCallback()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    visoidControllerCallback$Response(params: {
        context?: HttpContext;
        body: VisoidCallbackDto;
    }): Observable<StrictHttpResponse<void>> {
        const rb = new RequestBuilder(
            this.rootUrl,
            VisoidApiService.VisoidControllerCallbackPath,
            'post'
        );
        if (params) {
            rb.body(params.body, 'application/json');
        }

        return this.http
            .request(
                rb.build({
                    responseType: 'text',
                    accept: '*/*',
                    context: params?.context
                })
            )
            .pipe(
                filter((r: any) => r instanceof HttpResponse),
                map((r: HttpResponse<any>) => {
                    return (r as HttpResponse<any>).clone({
                        body: undefined
                    }) as StrictHttpResponse<void>;
                })
            );
    }

    /**
     * This method provides access to only to the response body.
     * To access the full response (for headers, for example), `visoidControllerCallback$Response()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    visoidControllerCallback(params: {
        context?: HttpContext;
        body: VisoidCallbackDto;
    }): Observable<void> {
        return this.visoidControllerCallback$Response(params).pipe(
            map((r: StrictHttpResponse<void>) => r.body as void)
        );
    }
}
